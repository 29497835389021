span.badge {
  background: red;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: absolute;
  border-radius: 50%;
  font-size: 0.8rem;
  z-index: 9;
  top: 7px;
  right: 0px;
  pointer-events: none;
}

@media screen and (max-width: 992px) {
  span.badge {
    top: 0px;
    right: 10px;
  }
}