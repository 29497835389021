span.view-image {
    margin-top: auto;
    cursor: pointer;
    /* background: lightskyblue; */
    padding: 15px;
    color: white;
    background: #076e56;
    line-height: 0em;
    border-radius: 50%;
    z-index: 999;
    animation: shadow-pulse-small 1s infinite;
}

.imagePanel {
    position: absolute;
    top: 50%;
    z-index: 5;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    border: 20px solid white;
}

.imagePanel img {
    max-height: 80vh;
    max-width: 80vw;
}

.el__content.hidden {
    display: none;
}

.hide-imagePanel {
    position: absolute;
    right: -50px;
    top: -50px;
    width: 50px;
    height: 50px;
    z-index: 99;
    opacity: 1;
    background: white;
    border-radius: 50%;
    padding: 20px;
    cursor: pointer;
}

.hide-imagePanel:hover {
    opacity: 1;
}

.hide-imagePanel:before,
.hide-imagePanel:after {
    position: absolute;
    left: 24px;
    top: 13px;
    content: ' ';
    height: 25px;
    width: 3px;
    background-color: #333;
    border-radius: 3px;
}

.hide-imagePanel:before {
    transform: rotate(45deg);
}

.hide-imagePanel:after {
    transform: rotate(-45deg);
}