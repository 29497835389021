form.html-form {
  display: flex;
  flex-direction: column;
  min-width: 250px;
  width: 100%;
}

form.html-form input[type="text"], form.html-form textarea {
  display: block;
  width: 100%;
  background: transparent;
  border: 1px solid #fff;
  padding: 10px 10px;
  margin-top: 5px;
  border-radius: 20px;
  margin-bottom: 10px;
  outline: none;
  color: white;
}

form.html-form input[type="submit"] {
  padding: 10px;
  border-radius: 20px;
  background: #fff;
  margin-top: 20px;
  border: 0;
}

form.html-form label {
  font-size: 0.8rem;
}
form.html-form input::placeholder, textarea::placeholder {
  font-size: 0.8rem;
  color: rgba(255,255,255,0.4);
}
input.error, textarea.error {box-shadow: inset 0 0 5px red;border-color: red!important;}
input[disabled], textarea[disabled] {
  opacity: 0.1;
}