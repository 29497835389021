.pr__page {
  position: absolute;
  top: 50%;
  left: 0%;
  height: 450px;
  right: 0%;
  background: white;
  color: black;
  font-size: 0.9em;
  z-index: 999;
  display: flex;
  transform: scale(1) translateY(-50%);
}

.pr__page.hidden {
  transition: transform 0.3s ease-out;
  transform: scale(0) translateY(-50%);
}

.pr__page_banner {
  flex: 1 1 50%;
}

.pr__page_content {
  flex: 1 1 50%;
  padding: 30px 20px;
}

.pr__page_banner img {
  width: 100%;
  height: 100%;
}

.pr__close-btn {
  z-index: -1;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;
  height: 20px;
  opacity: 0;
  pointer-events: none;
  transition: all 0s 0.45s;
  cursor: pointer;
}

.el.s--active .pr__close-btn {
  z-index: 5;
  opacity: 1;
  pointer-events: auto;
  transition: all 0s 1.4s;
}

.pr__close-btn:before,
.pr__close-btn:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 5px;
  margin-top: -4px;
  background: #000;
  opacity: 0;
  transition: opacity 0s;
}

.el.s--active .pr__close-btn:before,
.el.s--active .pr__close-btn:after {
  opacity: 1;
}

.pr__close-btn:before {
  transform: rotate(45deg) translateX(100%);
}

.el.s--active .pr__close-btn:before {
  transition: all 0.3s 1.4s cubic-bezier(0.72, 0.09, 0.32, 1.57);
  transform: rotate(45deg) translateX(0);
}

.pr__close-btn:after {
  transform: rotate(-45deg) translateX(100%);
}

.el.s--active .pr__close-btn:after {
  transition: all 0.3s 1.55s cubic-bezier(0.72, 0.09, 0.32, 1.57);
  transform: rotate(-45deg) translateX(0);
}

h1.pr__title--text {
  font-size: 1.2em;
  margin-bottom: 0.7em;
}

.pr__price {
  color: green;
  font-size: 1.5em;
  margin-top: 0.5em;
}
.price--buy {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pr__buy button {
  padding: 7px 50px;
  border: unset;
  background: green;
  color: white;
  margin-top: 10px;
  cursor: pointer;
}

.pr__page_content {
  padding-right: 40px;
  display: flex;
  font-size: 0.85rem;
  flex-direction: column;
}
.pr__buy button {
  padding: 7px 50px;
  border: unset;
  background: green;
  color: white;
  margin-top: 10px;
}

.pr__page_content .pr__text {
  flex: 1;
  line-height: 1.3em;
}