.dropdown {
    position: relative;
    display: inline-block;
    position: absolute;
    right: 0px;
    font-size: 1em;
    cursor: pointer;
}
.dropdown ul {
    list-style-type: none;
    margin: 0;
    padding: 10px 0;
    top: 45px;
    right: 0px;
    width: 270px;
    background-color: white;
    font-weight: bold;
    position: absolute;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 20px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}
.dropdown li a {
    color: #000;
    text-decoration: none;
    font-size: 1rem;
}
.dropdown li {
    padding: 12px 16px;
    font-size: 1rem;
    border-bottom: 1px solid #e5e5e5;
    color: black;
}
.dropdown li:last-child {
    border-bottom: none;
}
.dropdown li:hover {
    background-color: #e5e5e5;
    color: white;
}
.dropdown .button{
    background-color:transparent ;
    padding:5px;
    border-radius:5px;
    font-weight:bold;
    color:white;
    font-size: 16px;
}
.dropdown .button:before{
    content:"";
    position:absolute;
    width:0px;
    height:0px;
    border: 10px solid;
    border-color: green transparent transparent transparent;
    right:6px;
    top:12px;
}
@media screen and (max-width: 992px) {
    .dropdown {position: relative;margin: 10px;}

    .products.active .el_header {
        text-align: center;
        align-items: center;
    }
}
@media screen and (max-width: 768px) {
    .el.s--active .el__content {
        padding: 10px 0px!important;
    }
    .products.active .el_header {
        padding: 0 5px;
    }
    .products.active .el_header .dropdown {
        margin: 5px auto;
    }
}