.two-columns {
  display: flex;
  flex-direction: row;
}

.two-columns .table {
  width: 60%;
  padding: 10px;
}
.two-columns .user-details {
  width: 40%;
  padding: 10px;
}

.two-columns .table ul li {
  display: block;
}

.two-columns .table .image img {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 1;
}

.two-columns .table .image {
  position: relative;
}

.two-columns .table .details > div:first-child {
  flex: 1 1 100%;
}

.two-columns .table .details {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  flex-direction: column;
  display: flex;
  z-index: 3;
  padding: 15px;
}

.two-columns .table .remove {
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 10;
  opacity: 0.2;
  cursor: pointer;
}

.two-columns .table .remove:hover {
  opacity: 1;
}

.two-columns .table ul {
margin-top: 10px;
}

.two-columns .table ul li {
  position: relative;
  margin-top: 1rem;
  width: calc(50% - 20px);
  float: left;
  margin: 5px 10px;
}

.two-columns .table ul li:nth-child(2n + 1) {
  margin-left: 0px;
}

.two-columns .table ul li:nth-child(2n + 2) {
  margin-right: 0px;
}

@media screen and (max-width: 992px) {
  .two-columns {
    flex-direction: column;
  }

  .two-columns > div {
      margin-top: 50px;
      padding: 0px!important;
  }
  .two-columns .table {
    width: 100%;
  }

  .two-columns .table ul li {
      width: 100%;
      float: unset;
      margin: 20px 0px;
  }

  .two-columns .user-details {
      width: 100%;
  }
}