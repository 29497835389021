.pr__page.pr_el_loading.show {
    transform: scale(1) translateY(-50%);
}

.pr__page.pr_el_loading {
    transform: scale(0) translateY(-50%);
    transition: transform 0.3s linear;
}

.pr__page_banner.animated-background {
    height: 100%;
}